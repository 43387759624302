import React from "react"
import Container from "react-bootstrap/Container"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import AlertUnfinishedPage from "../../components/AlertUnfinishedPage"
import Seo from "../../components/Seo"

export const Head = () => {
  return <Seo customTitle="Oldtimer" />
}

export default function oldtimer() {
  return (
    <Layout>
      <Container className="my-5">
        <AlertUnfinishedPage />
        <PageTitle title="Oldtimer" />
        <p className="lh-text mb-5 lead font-weight-normal">
          Seit vielen Jahren fühlen wir uns den historischen Fahrzeugen
          verbunden. Aus diesem Grund richten wir verschiedene Veranstaltungen,
          wie das "Oldtimer-Frühschoppen" aus.
        </p>
      </Container>
    </Layout>
  )
}
